<template>
  <div>
    <input
      type="text"
      :placeholder="placeHolderText"
      class="form-control"
      v-model="keywordSearch"
      @input="$emit('input', $event.target.value)"
      @keyup="!autoCompleteProgress ? onKeyUp(keywordSearch) : ''"
    />
    <CListGroup
      class="dropdown-menu"
      style="width:100%"
      v-if="resultItems.length > 0"
    >
      <CListGroupItem
        v-for="(item, i) in resultItems"
        :key="i"
        @click="
          keywordSearch = ''
          onSelected(item)
        "
      >
        <strong>{{ item[itemText] }} ( {{ item[itemText3] }} )</strong>
        <br />
        <span>{{ item[itemText2] }}</span>
      </CListGroupItem>
    </CListGroup>
  </div>
</template>

<script>
export default {
  props: {
    placeHolderText: String,
    onKeyUp: Function,
    onSelected: Function,
    resultItems: Array,
    autoCompleteProgress: Boolean,
    itemText: String,
    itemText2: String,
    itemText3: String,
    itemId: String,
  },

  data() {
    return {
      keywordSearch: '',
    }
  },
}
</script>
