<template>
  <div v-if="isPermission">
    <div class="row" style="margin: 5px">
      <div class="col-md-10 col-9">
        <h2 class="font-weight-normal text-dark">
          {{ $t('management.adjustStockDoc') }}
        </h2>
      </div>
      <div class="col-md-2 col-3 text-right align-text-center">
        <a @click="$router.go(-1)">
          <CButton id="adjustStockDetailBackButton">
            <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
          </CButton>
        </a>
      </div>
    </div>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm" style=" border-radius: 0.1rem;">
        <CCardBody>
          <div class="row">
            <div class="col-md-12 col-12">
              <h4 class="font-weight-normal text-dark">
                {{ $t('information') }}
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h5 id="adjustStockDetailDocumentNumber" class="font-weight-normal text-left text-dark">
                {{ $t('documentNo') }} : {{ detail.documentNo }}
              </h5>
            </div>
            <div class="col-md-6">
              <h5 class="font-weight-normal text-right text-dark"  id="adjustStockDetailUser">
                {{ $t('username') }} : {{ createdBy }}
              </h5>
            </div>
          </div>
        </CCardBody>
      </CCard>
      <CCard class="shadow-sm" style=" border-radius: 0.1rem;">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8">
              <h4 class="font-weight-normal text-dark">
                {{ $t('adjustStockList') }}
              </h4>
              <!-- <p>
                   {{ $t("adjustDetail") }}
                </p> -->
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <table
                id="adjustStockDetailTable"
                style="white-space: nowrap"
                v-if="detail.items && detail.items.length != 0"
                class="table table-sm table-responsive table-bordered">
                <thead>
                  <tr>
                    <th class="text-dark font-weight-normal">#</th>
                    <th></th>
                    <th class="text-dark font-weight-normal">{{ $t('pluCode') }}</th>
                    <th class="text-dark font-weight-normal">{{ $t('productName') }}</th>
                    <th class="text-dark font-weight-normal text-right">{{ $t('beforeAdjust') }}</th>
                    <th class="text-dark font-weight-normal text-right">{{ $t('afterAdjust') }}</th>
                    <th class="text-dark font-weight-normal text-right">{{ $t('adjust') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in detail.items" :key="index">
                    <td :id="'adjustStockDetailIndex' + index" style="width:2%;" class="text-dark font-weight-normal">
                      {{ index + 1 }}
                    </td>
                    <td style="width:5%;">
                      <div v-if="item.remoteImagePath"
                        :style="{
                          'background-image': `url('${item.remoteImagePath}'), url('${noImg}')`,
                          'background-size': 'cover',
                          'background-position': 'center center',
                          'width': 'auto',
                          'border-radius': '2px',
                          'aspect-ratio': '1.3',
                          'min-width': '50px',
                        }"
                      ></div>
                      <div
                        v-else
                        style="border-radius: 2px"
                        :style="{
                          'aspect-ratio': '4 / 3',
                          'min-width': '50px',
                          backgroundColor: item.indexColor,
                        }"
                        :id="'adjustStockDetailImagePlaceholder' + index"
                      ></div>
                    </td>
                    <td style="width:10%" class="text-dark font-weight-normal">{{ item.productPLU.PLUCode }}</td>
                    <td style="width:40%" class="text-dark font-weight-normal">
                      {{ item.productPLU.name }}
                    </td>
                    <td style="width:10%" class="text-dark font-weight-normal">
                      <input 
                        v-if="floatValue(item.previousOnhand) >= 0"
                        v-model="item.previousOnhand"
                        class="form-control form-control-md text-right font-weight-normal text-dark"
                        disabled
                      /><input 
                        v-else
                        v-model="item.previousOnhand"
                        class="form-control form-control-md text-right font-weight-normal text-danger"
                        disabled
                      />
                    </td>
                    <td style="width:10%">
                      <input
                        class="form-control form-control-md text-right font-weight-normal text-dark"
                        :value="item.quantity"
                        disabled
                      />
                    </td>
                    <td style="width:10%">
                      <input v-if="floatValue(item.quantity - item.previousOnhand) > 0"
                        class="form-control form-control-md text-right text-success font-weight-normal"
                        :value="'+' + (item.quantity - item.previousOnhand)"
                        disabled
                      />
                      <input v-else-if="floatValue(item.quantity - item.previousOnhand) < 0"
                        class="form-control form-control-md text-right font-weight-normal text-danger"
                        :value="item.quantity - item.previousOnhand"
                        disabled
                      /><input v-else
                        class="form-control form-control-md text-right font-weight-normal text-dark"
                        :value="item.quantity - item.previousOnhand"
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="form-group">
                <label class="font-weight-normal text-dark">
                  {{ $t('reason') }}
                </label>
                <textarea
                  disabled
                  class="form-control"
                  v-model="detail.note"
                  rows="5"
                  id="adjustStockDetailReasonTextarea"
                ></textarea>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/services/local'
import Pagination from '@/containers/Pagination'
import autocomplete from '@/containers/AutoComplete'
import util from '@/util/util'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
    autocomplete,
  },
  data() {
    return {
      detail: {},
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      return permis.findPermissionRead('inventory', '/inventory/adjust-stock')
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    createdBy() {
      let created = ''
      if (this.detail.created_by === undefined) {
        created = '-'
      } else {
        created = this.detail.created_by.username
      }
      return created
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
  },
  created() {
    this.getAdjustStockDetail()
  },
  methods: {
    ...util,
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    getAdjustStockDetail() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      const adjustStockObjectId = this.$route.params.adjustStockObjectId
      const params = { shopObjectId: shopObjectId }
      const headers = { shopObjectId: shopObjectId }

      let list = []
      axios({
        url: '/api/v1.0/' + uid + '/getadjuststock/' + adjustStockObjectId,
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.detail = res.data.data.documents
          let items = this.detail.items
          items.forEach(function(item) {
            axios({
              method: 'get',
              url:
                '/api/v1.0/image/' +
                uid +
                '/ProductPLU/' +
                item.productPLU.objectId,
            })
              .then((respone) => {
                if (respone.data.data.remoteImagePath !== undefined) {
                  item.remoteImagePath = respone.data.data.remoteImagePath
                } else {
                  item.indexColor = util.generateColor(
                    respone.data.data.indexColor
                  )
                }
                list.push(item)
              })
              .catch((error) => {
                console.log(error)
              })
          })

          this.detail.items = list
          this.items = this.detail.items
        })
        .catch((error) => {
          console.log(error)
        })
    },
    floatValue(value) {
      return parseFloat(value)
    },
  },
}
</script>
